import React, { useEffect, useRef, useState } from 'react'
import InputSearch from './InputSearch'
import DropDownOption from './DropDownOption'
import { Spinner } from '@nextui-org/spinner';
import { moviePerPage } from '@/src/constant';
import { ScrollShadow } from '@nextui-org/scroll-shadow';
import { Pagination } from '@nextui-org/pagination';
import { getBySearch } from '@/src/server/api/movies/getBySearch';
import MovieList from '../List/MovieList';
import { IoIosWarning } from "react-icons/io";
import { useMutation } from "@tanstack/react-query";

const SearchResult = ({movies,onClose}) => {
  return (
    movies.length > 0 &&
    <ScrollShadow className="max-h-[70vh] p-2 overflow-hidden hover:overflow-auto">
      <MovieList movies={movies} type={undefined} onClose={onClose} fromSearch={true}/>
    </ScrollShadow>
  )
}

export default function SearchForm({onClose,categories}) {
    const [searchWithCat, setSearchWithCat] = React.useState(0);
    const [searchValue, setSearchValue] = React.useState('');
    const [firstUpdate, setFirstUpdate] = useState(true);
    const [isHiddenResult,setIsHiddenResult] = useState(false);
    const [currentPage,setCurrentPage] = useState(1);
    const [totalCord,setTotalCord] = useState(0);
    const take = moviePerPage.search_take;
    const ref = useRef(null);
    const [queryKey, setQueryKey] = useState({
          s:searchValue,
          c:searchWithCat,
          take,
          skip:currentPage,
          withTotal:true,
          include:null,
          where:{},
          orderBy:{
            updatedAt: 'desc'
          }
    });
    const mutation = useMutation({
      mutationFn: (queryKey) => {
          return getBySearch(queryKey).then((res) => {
              if(res.success){
                if(queryKey.withTotal){
                  setTotalCord(res.data.total);
                  return res.data;
                }else{
                  return res;
                }
              }
          })
      },
    })

    useEffect(() => {
        const handleOutSideClick = (event) => {
          if (!ref.current?.contains(event.target)) {
            setIsHiddenResult(false);
          }
        };
        window.addEventListener("mousedown", handleOutSideClick);
        return () => {
          window.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [ref]);

    useEffect(() => {
        setFirstUpdate(false)
    },[])

    useEffect(() => {
      if(searchValue !== ''){
        const cloneQueryKey = {...queryKey}
        cloneQueryKey.withTotal = true;
        cloneQueryKey.s = searchValue;
        cloneQueryKey.c = searchWithCat;
        cloneQueryKey.skip = currentPage > 1 ? (currentPage - 1) * queryKey.take : undefined;
        setCurrentPage(1);
        setQueryKey(cloneQueryKey)
        if(!firstUpdate){
          mutation.mutate(cloneQueryKey)
        }
      }
    }, [searchWithCat,searchValue]);
    
    useEffect(() => {
      if(currentPage >= 1){
        const cloneQueryKey = {...queryKey}
        cloneQueryKey.skip = currentPage > 1 ? (currentPage - 1) * queryKey.take : undefined;
        cloneQueryKey.withTotal = false;
        setQueryKey(cloneQueryKey)
        if(!firstUpdate){
          mutation.mutate(cloneQueryKey)
        }
      }
    }, [currentPage]);
    return (
        <div ref={ref}>
            <div className='flex mb-4'>
              <InputSearch setIsHiddenResult={setIsHiddenResult} setSearchValue={setSearchValue}/>
              <DropDownOption setSearchWithCat={setSearchWithCat} categories={categories}/>
            </div>
            <div className={`${!isHiddenResult ? 'opacity-0 invisible' : 'opacity-100 visible'} transition ease-in-out delay-150 left-0 shadow-lg top-full md:absolute w-full md:h-[calc(100vh_-_110px)] h-[calc(100vh_-_200px)] rounded-[10px] z-[-1] bg-background border`}>
              <div className={`h-full flex flex-col w-full ${!mutation.data ? 'justify-center' : 'justify-between'}  p-4`}>
                { !mutation.data || mutation.data.length === 0 && <p className='text-small font-bold text-warning flex items-center gap-2 absolute top-2 left-2'><IoIosWarning/> Không có kết quả hãy điền chính xác tên phim </p> }
                { mutation.isPending || !mutation.data ? <Spinner size='md' color='danger'/> : <SearchResult onClose={onClose === undefined ? setIsHiddenResult : onClose} movies={mutation.data.data}/> }
                { totalCord > 0 &&
                  <div className="mt-8 flex justify-end">
                    <Pagination total={Math.ceil(totalCord / queryKey.take)} initialPage={currentPage} onChange={(page) => setCurrentPage(page)}/>
                  </div>
                }
              </div>
            </div>
        </div>
    )
}
