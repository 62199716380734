"use client"
import React, { useEffect } from 'react'
import Image from 'next/image';
import { useTheme } from 'next-themes';
import SocialButtonList from './Utils/SocialButtonList';
import Link from 'next/link';

export default function Footer({categories,countries}) {
    const { theme } = useTheme();
    const [logoLink, setLogoLink] = React.useState('/logo/logo_dark.svg');
    useEffect(() => {
        if(theme === "dark"){
            setLogoLink('/logo/logo_dark.svg')
        }else{
            setLogoLink('/logo/logo_light.svg')
        }
	}, [theme]);
    const infomations = [
        {
            id:1,
            name:"Giới Thiệu",
            slug:'trang/gioi-thieu',
        },
        {
            id:2,
            name:"Liên Hệ Chúng Tôi",
            slug:'trang/lien-he-chung-toi',
        },
        {
            id:3,
            name:"Điều Khoản Sử Dụng",
            slug:'trang/dieu-khoan-su-dung',
        },
        {
            id:4,
            name:"Chính Sách Riêng Tư",
            slug:'trang/chinh-sach-rieng-tu',
        },
        {
            id:5,
            name:"Khiếu Nại Bản Quyền",
            slug:'trang/khieu-nai-ban-quyen',
        }
    ];
    return (
        <div className="bg-background w-full">
            <div className="max-w-[1536px] m-auto py-8 h-full">
                <div className="flex flex-wrap justify-center gap-8">
                    <div className="xl:max-w-[calc(40%_-_96px)] lg:max-w-[calc(40%_-_96px)] md:max-w-[calc(50%_-_16px)] max-w-[calc(70%_-_32px)] w-full">
                        <div className='flex flex-col'>
                            <Image width={150} height={100} className="h-full" src={logoLink} alt={"PhimHot"}/>
                            <p className="text-small max-w-[400px] my-4">PhimHot - Một Trang Webapp xem phim Online với giao diện thân thiện cùng với công nghệ mới giúp tăng trải nghiệm người dùng. Nguồn phim được tổng hợp từ các website lớn với đa dạng các đầu phim và thể loại vô cùng phong phú.</p>
                        </div>
                    </div>
                    <div className="xl:max-w-[20%] lg:max-w-[20%] md:max-w-[calc(50%_-_16px)] max-w-[25%] w-full">
                        <h1 className="text-lg font-semibold my-4">Phim Hot</h1>
                        <ul className="list-none">
                            {categories.map((item) => (
                                <li className='my-2' key={item.id}><Link prefetch={false} className='text-foreground' href={`/danh-muc/${item.slug}`}>{item.name}</Link></li>
                            ))}
                        </ul>
                    </div>
                    <div className="xl:max-w-[20%] lg:max-w-[20%] md:max-w-[calc(50%_-_16px)] max-w-[calc(45%_-_16px)] w-full">
                        <h1 className="text-lg font-semibold my-4">Quốc Gia</h1>
                        <ul className="list-none">
                            {countries.splice(0,6).map((item) => (
                                <li className='my-2' key={item.id}><Link prefetch={false} className='text-foreground' href={`/quoc-gia/${item.slug}`}>Phim {item.name}</Link></li>
                            ))}
                        </ul>
                    </div>
                    <div className="xl:max-w-[20%] lg:max-w-[20%] md:max-w-[calc(50%_-_16px)] max-w-[calc(55%_-_16px)] w-full">
                        <h1 className="text-lg font-semibold my-4">Thông Tin</h1>
                        <ul className="list-none">
                            {infomations.map((item) => (
                                <li className='my-2' key={item.id}><Link prefetch={false} className='text-foreground' href={`/${item.slug}`}>{item.name}</Link></li>
                            ))}
                        </ul>
                        <SocialButtonList/>
                    </div>
                </div>
            </div>
        </div>
    )
}
