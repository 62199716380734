"use client"
import React, { useEffect } from "react";
import { useTheme } from 'next-themes'
import { FiMoon } from "react-icons/fi";
import { BsSun } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import NextLink from "next/link";
import { Navbar, NavbarContent, NavbarItem } from "@nextui-org/navbar";
import { Button } from "@nextui-org/button";
import { FaTelegramPlane } from "react-icons/fa";

export default function Topbar({phone,email}) {
    const { theme, setTheme } = useTheme();
    const LightButton = () => {
        return (
            <Button
                aria-label="Tap Turn Light"
                onClick={() => setTheme('light')}
                isIconOnly
                className="p-2 "
                variant="light"
            >
                <BsSun size={15}/>
            </Button>
        )
    }
    const DarkButton = () => {
        return (
            <Button
                aria-label="Tap Turn Dark"
                onClick={() => setTheme('dark')}
                isIconOnly
                className="p-2"
                variant="light"
            >
                <FiMoon size={15}/>
            </Button>
        )
    }
    const buttonTheme  = {
        'dark': LightButton,
        'light': DarkButton,
      };
    const [buttonThemeState, setButtonThemeState] = React.useState(buttonTheme.light);
    useEffect(() => {
        if(theme === "dark"){
            setButtonThemeState(buttonTheme.dark);
        }else{
            setButtonThemeState(buttonTheme.light);
        }
	}, [theme]);
    return (
        <Navbar maxWidth="2xl" className="bg-background" height={"2.5rem"} isBordered>
            <NavbarContent justify="center">
                <NavbarItem className="md:flex gap-4 text-xs items-center hidden">
                    { phone ? (
                        <div className="flex gap-2 items-center">
                            <FaTelegramPlane size={13}/>
                            <NextLink target="_blank" href={`https://t.me/${phone}`}>{phone}</NextLink>
                        </div>
                    ) : null }
                    { email ? (
                        <div className="flex gap-2 items-center">
                            <MdEmail size={15}/>
                            <NextLink href={`mailto:${email}`}>{email}</NextLink>
                        </div>
                    ) : null }
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem>
                    {
                        buttonThemeState
                    }
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    );
}
