"use client"
import React from "react";
import Link from 'next/link';
import {Image} from "@nextui-org/image";
import { FaPlay } from "react-icons/fa6";
import { useBreakpoint } from "@/src/hooks/useBreakpoint";
import { Card,CardHeader,CardFooter } from "@nextui-org/card";
import { Button } from "@nextui-org/button";
import { FaRegHeart } from "react-icons/fa";
import { Chip } from "@nextui-org/chip";

export default function MovieSideBarCard({movie,index,type}) {
    const { isDownLg } = useBreakpoint('lg');
    const [liked, setLiked] = React.useState(false);

    return (
        <Link prefetch={false} href={`/xem-phim/${movie.slug}`}>
            <Card isFooterBlurred className="w-full h-[200px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                    <Chip color="primary" radius="md">
                        <p className="text-tiny text-white uppercase font-bold">Lượt Xem { movie[`view_${type}`] === 0 ? movie.view_total : movie[`view_${type}`] }</p>
                    </Chip>
                </CardHeader>
                <Image
                    removeWrapper
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src={movie.image}
                />
                <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                    <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                            <h4 className="text-white/90 font-medium text-sm">{movie.name}</h4>
                            <p className="text-tiny text-white/60">{movie.origin_name}</p>
                        </div>
                    </div>
                    <Button radius="full" size="sm" isIconOnly><FaPlay/></Button>
                </CardFooter>
            </Card>
        </Link>
    );
}
