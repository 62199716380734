"use client"
import { ScrollShadow } from '@nextui-org/scroll-shadow'
import React from 'react'
import moment from 'moment';
import Link from 'next/link';
import { Button } from '@nextui-org/button';
import { useBreakpoint } from "@/src/hooks/useBreakpoint";

export default function YearPublish({setIsMenuOpen}) {
    const { isDownMd } = useBreakpoint('md');
    const currentYear = moment().year();
    const years = [];
    for (let year = 1975; year <= currentYear; year++) {
        years.push(year);
    }
    return (
        <div>
            <ScrollShadow className="h-[200px]">
                <div className="grid sm:grid-cols-4 grid-cols-3 gap-2">
                    {
                        years.reverse().map((year,index) => (
                            <Button onClick={() => isDownMd ? setIsMenuOpen(false) : null} key={index} prefetch={false} as={Link} href={`/nam-phat-hanh/${year}`} variant='ghost' color='primary' className='text-center font-bold text-foreground' size='sm'>
                                {year}
                            </Button>
                        ))
                    }
                </div>
            </ScrollShadow>
        </div>
      )
}
